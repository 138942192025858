import { Time } from "@foxglove/rostime"


export const TOPIC_IMAGE_PANEL_KEYFRAME = 'TOPIC_IMAGE_PANEL_KEYFRAME'

export type MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME = {
  topic: string
  keyframes: bigint[]
  id: string
}

export const OFF_TOPIC_IMAGE_PANEL_KEYFRAME = 'OFF_TOPIC_IMAGE_PANEL_KEYFRAME'

export type OFF_MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME = {
  topic: string
  id: string
}


export const SCRUBBER_ON_SEEK = 'SCRUBBER_ON_SEEK'
export type TYPE_SCRUBBER_ON_SEEK = {
  minTopic: string
  time: Time
}
