import { CowaPanelsState } from "@foxglove/studio-base/services/ILayoutStorage";
import { message } from "antd";
import axios from "axios";

const is_dev = process.env.NODE_ENV === "development";
const domain = is_dev ? "http://localhost:8000" : "https://foxglove.cowarobot.cn";

const baseURL = `${domain}/api/foxglove`;

console.log(`cur mode: ${process.env.NODE_ENV}`);

const instance = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 3000, // 3s
  withCredentials: false,
});

instance.interceptors.request.use(
  (req) => {
    return req;
  },
  (e) => {
    console.log(e);
    return Promise.reject(e);
  },
);

instance.interceptors.response.use(
  (res) => {
    if (res.data.code === 0) {
      return res.data;
    } else {
      return Promise.reject(res.data.msg ?? '接口发生错误')
    }

  },
  (e) => {
    if (e.code === "ERR_NETWORK") { // from axios
      return Promise.reject('网络发生错误');
    } else {
      return Promise.reject(e instanceof String ? e : '接口发生错误');
    }
  },
);

const ping = () => instance.get("hdmap/test");

const post_check_user = (access_token: string) =>
  instance.post("user/check_user", { access_token });
// https://zi9wcyo1i5.feishu.cn/docx/E2S7dWlM3oDOfgx2hoycniM0n2g
const sso_code_login = (sso_code: string) => instance.post(`user/sso_code_login`, { sso_code });

const refresh_access_token = (refresh_token: string) =>
  instance.post("user/refresh_access_token", { refresh_token });

// judge access_token before refresh_token

export const enterLoginPage = () => {
  ping().then(() => {
    window.open(
      `https://sso.cowarobot.com/?app_id=sso_924f59f4af90&app_url=${encodeURIComponent(
        location.origin + '/login_done.html',
      )}#/login`,
    );
  }).catch((e: String) => {
    message.error(e, 2);
  });
}

const save_label_data = (label_data: any) => instance.post('user/save_label_data', { label_data })

// layout
const save_layout = (
  layouts: CowaPanelsState[],
  feishu_id: string,
) => instance.post(`layouts/save`, { layouts, feishu_id }, { timeout: 20000 }) // 20s


const list_one_layout = (
  feishu_id: string | null,
  layout_uuid: string
) => instance.post(`layouts/list_one`, { feishu_id, layout_uuid })


const list_layouts = (
  feishu_id: string,
  list_detail = false
) => instance.post(`layouts/list`, { feishu_id, list_detail })

const delete_layouts = (
  feishu_id: string,
  layout_uuids: string[]
) => instance.post(`layouts/delete`, { feishu_id, layout_uuids })


export default {
  ping, sso_code_login, refresh_access_token,
  post_check_user,
  save_label_data,
  save_layout, list_one_layout, list_layouts, delete_layouts
};
