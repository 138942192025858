import { NOTIFICATION_TYPES_MAP } from "@foxglove/studio-base/config/localStorageTypes";
import { Popconfirm } from "antd";
import { useCallback, useLayoutEffect, useState } from "react";

export const PopconfirmNotification = (props: {
  notificationType: keyof typeof NOTIFICATION_TYPES_MAP;
}) => {
  const { notificationType } = props;
  const [open, setOpen] = useState(false);
  useLayoutEffect(() => {
    const pf = localStorage.getItem(notificationType);
    if (pf === "1") {
      setOpen(false);
    } else {
      setOpen(true);
    }
    return () => {
      setOpen(false);
    };
  }, [notificationType]);

  const onConfirm = useCallback(() => {
    localStorage.setItem(notificationType, "1");
    setOpen(false);
  }, []);

  return (
    <Popconfirm
      title=""
      overlayInnerStyle={{
        marginBottom: "4.3rem",
      }}
      showArrow={false}
      open={open}
      placement="top"
      destroyTooltipOnHide
      description={NOTIFICATION_TYPES_MAP[notificationType].description}
      onConfirm={onConfirm}
      showCancel={false}
    />
  );
};
