import { useShallowMemo } from "@foxglove/hooks";
import { SSO_TOKEN_UNION_TYPE, SSO_UNION } from "@foxglove/studio-base/config/localStorageTypes";
import CurrentCowaUserContext, {
  CowaUser,
} from "@foxglove/studio-base/context/cowa/CurrentCowaUserContext";
import cowaApi from "@foxglove/studio-base/cowaApi";
import { useAsyncEffect, useInterval, useLocalStorageState } from "ahooks";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";

const CurrentCowaUserProvider = (props: PropsWithChildren<unknown>) => {
  const [currentCowaUser, setCurrentCowaUser] = useState<CowaUser | undefined>();
  const [loadingUser, serLoadinguser] = useState(true);

  const [ssoCode, setSsoCode] = useLocalStorageState<string>(SSO_UNION["SSO_CODE_TYPE"], {
    defaultValue: "",
    listenStorageChange: true,
    serializer: (v) => v,
    deserializer: (v) => v,
  });

  const signOut = useCallback(async () => {
    setSsoCode();
    setCurrentCowaUser(undefined);
    serLoadinguser(false);
    localStorage.removeItem(SSO_UNION["SSO_TOKEN_UNION_TYPE"]);
  }, []);

  const refreshStatus = useCallback(async () => {
    const str = localStorage.getItem(SSO_UNION["SSO_TOKEN_UNION_TYPE"]);
    if (!str) return;
    const { refresh_token } = JSON.parse(str) as SSO_TOKEN_UNION_TYPE;

    return cowaApi.refresh_access_token(refresh_token).then(async (v: any) => {
      if (v.code !== 0) return;

      const { access_token, refresh_token } = v.data.token;
      await cowaApi.post_check_user(access_token).then((v: any) => {
        if (v.code === 0) {
          localStorage.setItem(
            SSO_UNION["SSO_TOKEN_UNION_TYPE"],
            JSON.stringify({ access_token, refresh_token })!,
          );
          setCurrentCowaUser(v.data.user);
        }
      });
    });
  }, []);
  // useInterval(refreshStatus, 1000 * 60 * 30, { immediate: false }); // 30min
  useInterval(refreshStatus, 1000 * 60 * 30, { immediate: false }); // 30min

  useAsyncEffect(async () => {
    // console.log("ssoCode", ssoCode);
    if (ssoCode === "" || !ssoCode) return;
    serLoadinguser(true);

    await cowaApi
      .sso_code_login(ssoCode)
      .then(async (v: any) => {
        if (v.code === 0) {
          const { user, token } = v.data;

          localStorage.setItem(SSO_UNION["SSO_TOKEN_UNION_TYPE"], JSON.stringify(token)!);
          setCurrentCowaUser(user);
          setSsoCode();
        } else {
          console.warn(v.msg);
        }
      })
      .catch((e) => {
        console.warn(e);
      });

    serLoadinguser(false);
  }, [ssoCode]);

  useAsyncEffect(async () => {
    // ping

    const res = await cowaApi.ping().catch(() => "bad"); // 网络不通
    if (res === "bad") {
      return signOut();
    }

    const str = localStorage.getItem(SSO_UNION["SSO_TOKEN_UNION_TYPE"]);
    if (!str) {
      setCurrentCowaUser(undefined);
      serLoadinguser(false);
      return;
    }

    const { access_token, refresh_token } = JSON.parse(str) as SSO_TOKEN_UNION_TYPE;
    if (!access_token || !refresh_token) {
      setCurrentCowaUser(undefined);
      serLoadinguser(false);
      return;
    }

    await cowaApi
      .post_check_user(access_token)
      .then(async (v: any) => {
        if (v.code === 0) {
          const { user } = v.data;

          setCurrentCowaUser(user);
          return;
        }
        // try : refresh token
        await refreshStatus();
      })
      .catch((_e) => {
        setCurrentCowaUser(undefined);
      });

    serLoadinguser(false);
  }, []);

  useEffect(() => {
    if (currentCowaUser) {
      localStorage.setItem(SSO_UNION["SSO_FEISHU_ID_TYPE"], currentCowaUser.feishu_id);
    } else {
      localStorage.removeItem(SSO_UNION["SSO_FEISHU_ID_TYPE"]);
    }
  }, [currentCowaUser]);

  const value = useShallowMemo({ currentCowaUser, loadingUser, signOut, refreshStatus });

  return (
    <CurrentCowaUserContext.Provider value={value}>
      {props.children}
    </CurrentCowaUserContext.Provider>
  );
};

export default CurrentCowaUserProvider;
