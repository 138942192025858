// This Source Code Form is subject to the terms of the Mozilla Public
// License, v2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/

import AddIcon from "@mui/icons-material/Add";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import FileOpenOutlinedIcon from "@mui/icons-material/FileOpenOutlined";
import {
  Button,
  IconButton,
  Switch,
  FormGroup,
  FormControlLabel,
  CircularProgress,
  useTheme,
  TextField,
} from "@mui/material";
import { partition } from "lodash";
import moment from "moment";
import { useSnackbar } from "notistack";
import path from "path";
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useMountedState } from "react-use";
import useAsyncFn from "react-use/lib/useAsyncFn";

import Logger from "@foxglove/log";
import { useUnsavedChangesPrompt } from "@foxglove/studio-base/components/LayoutBrowser/UnsavedChangesPrompt";
import { SidebarContent } from "@foxglove/studio-base/components/SidebarContent";
import Stack from "@foxglove/studio-base/components/Stack";
import { useAnalytics } from "@foxglove/studio-base/context/AnalyticsContext";
import {
  LayoutState,
  useCurrentLayoutActions,
  useCurrentLayoutSelector,
} from "@foxglove/studio-base/context/CurrentLayoutContext";
import { PanelsState } from "@foxglove/studio-base/context/CurrentLayoutContext/actions";
import { useLayoutManager } from "@foxglove/studio-base/context/LayoutManagerContext";
import LayoutStorageDebuggingContext from "@foxglove/studio-base/context/LayoutStorageDebuggingContext";
import useCallbackWithToast from "@foxglove/studio-base/hooks/useCallbackWithToast";
import { useConfirm } from "@foxglove/studio-base/hooks/useConfirm";
import { usePrompt } from "@foxglove/studio-base/hooks/usePrompt";
import { defaultPlaybackConfig } from "@foxglove/studio-base/providers/CurrentLayoutProvider/reducers";
import { AppEvent } from "@foxglove/studio-base/services/IAnalytics";
import {
  CowaPanelsState,
  Layout,
  LayoutID,
  layoutIsShared,
} from "@foxglove/studio-base/services/ILayoutStorage";
import { downloadTextFile } from "@foxglove/studio-base/util/download";
import showOpenFilePicker from "@foxglove/studio-base/util/showOpenFilePicker";
import { mdiDatabase } from "@mdi/js";
import Icon from "@mdi/react";

import LayoutSection from "./LayoutSection";
import helpContent from "./index.help.md";
import { useLayoutBrowserReducer } from "./reducer";
import { debugBorder } from "./styles";
import {
  CowaUser,
  useCurrentCowaUser,
  useUserLoginModal,
} from "@foxglove/studio-base/context/cowa/CurrentCowaUserContext";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { message, Modal, Popconfirm, Space, Table } from "antd";
import { v4 as uuid } from "uuid";
import cowaApi from "@foxglove/studio-base/cowaApi";
import { ILayoutManager } from "@foxglove/studio-base/services/ILayoutManager";
import { useDebounceEffect } from "ahooks";

const log = Logger.getLogger(__filename);

const selectedLayoutIdSelector = (state: LayoutState) => state.selectedLayout?.id;

interface LayoutCloudType {
  layout_uuid: string;
  layout_name: string;
  layout_data?: CowaPanelsState;
  updateTime: string;
}

export default function LayoutBrowser({
  currentDateForStorybook,
}: React.PropsWithChildren<{
  currentDateForStorybook?: Date;
}>): JSX.Element {
  const [messageApi, contextHolder] = message.useMessage();
  const tokenId = useMemo(() => uuid(), []);

  const theme = useTheme();
  const isMounted = useMountedState();
  const { enqueueSnackbar } = useSnackbar();
  const layoutManager = useLayoutManager();
  const prompt = usePrompt();
  const analytics = useAnalytics();
  const confirm = useConfirm();
  const { unsavedChangesPrompt, openUnsavedChangesPrompt } = useUnsavedChangesPrompt();

  const currentLayoutId = useCurrentLayoutSelector(selectedLayoutIdSelector);
  const { setSelectedLayoutId } = useCurrentLayoutActions();

  const [state, dispatch] = useLayoutBrowserReducer({
    busy: layoutManager.isBusy,
    error: layoutManager.error,
    online: layoutManager.isOnline,
  });

  const { currentCowaUser } = useCurrentCowaUser();
  const userLoginModal = useUserLoginModal();

  const [openModal, setOpenModal] = useState(false);
  const [layoutCloudList, setLayoutCloudList] = useState<LayoutCloudType[]>([]);

  useLayoutEffect(() => {
    const busyListener = () => {
      dispatch({ type: "set-busy", value: layoutManager.isBusy });
    };
    const onlineListener = () => dispatch({ type: "set-online", value: layoutManager.isOnline });
    const errorListener = () => dispatch({ type: "set-error", value: layoutManager.error });
    busyListener();
    onlineListener();
    errorListener();
    layoutManager.on("busychange", busyListener);
    layoutManager.on("onlinechange", onlineListener);
    layoutManager.on("errorchange", errorListener);
    return () => {
      layoutManager.off("busychange", busyListener);
      layoutManager.off("onlinechange", onlineListener);
      layoutManager.off("errorchange", errorListener);
    };
  }, [dispatch, layoutManager]);

  const [layouts, reloadLayouts] = useAsyncFn(
    async () => {
      const [shared, personal] = partition(
        await layoutManager.getLayouts(),
        layoutManager.supportsSharing ? layoutIsShared : () => false,
      );
      return {
        personal: personal.sort((a, b) => a.name.localeCompare(b.name)),
        shared: shared.sort((a, b) => a.name.localeCompare(b.name)),
      };
    },
    [layoutManager],
    { loading: true },
  );

  useEffect(() => {
    const processAction = async () => {
      if (!state.multiAction) {
        return;
      }

      const id = state.multiAction.ids[0];
      if (id) {
        try {
          switch (state.multiAction.action) {
            case "delete":
              await layoutManager.deleteLayout({ id: id as LayoutID });
              dispatch({ type: "shift-multi-action" });
              break;
            case "duplicate": {
              const layout = await layoutManager.getLayout(id as LayoutID);
              if (layout) {
                await layoutManager.saveNewLayout({
                  name: `${layout.name} copy`,
                  data: layout.working?.data ?? layout.baseline.data,
                  permission: "CREATOR_WRITE",
                });
              }
              dispatch({ type: "shift-multi-action" });
              break;
            }
            case "revert":
              await layoutManager.revertLayout({ id: id as LayoutID });
              dispatch({ type: "shift-multi-action" });
              break;
            case "save":
              await layoutManager.overwriteLayout({ id: id as LayoutID });
              dispatch({ type: "shift-multi-action" });
              break;
          }
        } catch (err) {
          enqueueSnackbar(`Error processing layouts: ${err.message}`, { variant: "error" });
          dispatch({ type: "clear-multi-action" });
        }
      }
    };

    processAction().catch((err) => log.error(err));
  }, [dispatch, enqueueSnackbar, layoutManager, state.multiAction]);

  useEffect(() => {
    const listener = () => void reloadLayouts();
    layoutManager.on("change", listener);
    return () => layoutManager.off("change", listener);
  }, [layoutManager, reloadLayouts]);

  // Start loading on first mount
  useEffect(() => {
    reloadLayouts().catch((err) => log.error(err));
  }, [reloadLayouts]);

  /**
   * Don't allow the user to switch away from a personal layout if they have unsaved changes. This
   * currently has a race condition because of the throttled save in CurrentLayoutProvider -- it's
   * possible to make changes and switch layouts before they're sent to the layout manager.
   * @returns true if the original action should continue, false otherwise
   */
  const promptForUnsavedChanges = useCallback(async () => {
    const currentLayout =
      currentLayoutId != undefined ? await layoutManager.getLayout(currentLayoutId) : undefined;
    if (
      currentLayout != undefined &&
      layoutIsShared(currentLayout) &&
      currentLayout.working != undefined
    ) {
      const result = await openUnsavedChangesPrompt(currentLayout);
      switch (result.type) {
        case "cancel":
          return false;
        case "discard":
          await layoutManager.revertLayout({ id: currentLayout.id });
          void analytics.logEvent(AppEvent.LAYOUT_REVERT, {
            permission: currentLayout.permission,
            context: "UnsavedChangesPrompt",
          });
          return true;
        case "overwrite":
          await layoutManager.overwriteLayout({ id: currentLayout.id });
          void analytics.logEvent(AppEvent.LAYOUT_OVERWRITE, {
            permission: currentLayout.permission,
            context: "UnsavedChangesPrompt",
          });
          return true;
        case "makePersonal":
          // We don't use onMakePersonalCopy() here because it might need to prompt for unsaved changes, and we don't want to select the newly created layout
          await layoutManager.makePersonalCopy({
            id: currentLayout.id,
            name: result.name,
          });
          void analytics.logEvent(AppEvent.LAYOUT_MAKE_PERSONAL_COPY, {
            permission: currentLayout.permission,
            syncStatus: currentLayout.syncInfo?.status,
            context: "UnsavedChangesPrompt",
          });
          return true;
      }
    }
    return true;
  }, [analytics, currentLayoutId, layoutManager, openUnsavedChangesPrompt]);

  const onSelectLayout = useCallbackWithToast(
    async (
      item: Layout,
      { selectedViaClick = false, event }: { selectedViaClick?: boolean; event?: MouseEvent } = {},
    ) => {
      if (selectedViaClick) {
        if (!(await promptForUnsavedChanges())) {
          return;
        }
        void analytics.logEvent(AppEvent.LAYOUT_SELECT, { permission: item.permission });
      }
      if (event?.ctrlKey === true || event?.metaKey === true || event?.shiftKey === true) {
        if (item.id !== currentLayoutId) {
          dispatch({
            type: "select-id",
            id: item.id,
            layouts: layouts.value,
            modKey: event.ctrlKey || event.metaKey,
            shiftKey: event.shiftKey,
          });
        }
      } else {
        setSelectedLayoutId(item.id);
        dispatch({ type: "select-id", id: item.id });
      }
    },
    [
      analytics,
      currentLayoutId,
      dispatch,
      layouts.value,
      promptForUnsavedChanges,
      setSelectedLayoutId,
    ],
  );

  const onRenameLayout = useCallbackWithToast(
    async (item: Layout, newName: string) => {
      await layoutManager.updateLayout({ id: item.id, name: newName });
      void analytics.logEvent(AppEvent.LAYOUT_RENAME, { permission: item.permission });
    },
    [analytics, layoutManager],
  );

  const onDuplicateLayout = useCallbackWithToast(
    async (item: Layout) => {
      if (state.selectedIds.length > 1) {
        dispatch({ type: "queue-multi-action", action: "duplicate" });
        return;
      }

      if (!(await promptForUnsavedChanges())) {
        return;
      }
      const newLayout = await layoutManager.saveNewLayout({
        name: `${item.name} copy`,
        data: item.working?.data ?? item.baseline.data,
        permission: "CREATOR_WRITE",
      });
      await onSelectLayout(newLayout);
      void analytics.logEvent(AppEvent.LAYOUT_DUPLICATE, { permission: item.permission });
    },
    [
      analytics,
      dispatch,
      layoutManager,
      onSelectLayout,
      promptForUnsavedChanges,
      state.selectedIds.length,
    ],
  );

  const onDeleteLayout = useCallbackWithToast(
    async (item: Layout) => {
      if (state.selectedIds.length > 1) {
        dispatch({ type: "queue-multi-action", action: "delete" });
        return;
      }

      void analytics.logEvent(AppEvent.LAYOUT_DELETE, { permission: item.permission });

      // If the layout was selected, select a different available layout.
      //
      // When a users current layout is deleted, we display a notice. By selecting a new layout
      // before deleting their current layout we avoid the weirdness of displaying a notice that the
      // user just deleted their current layout which is somewhat obvious to the user.
      if (currentLayoutId === item.id) {
        const storedLayouts = await layoutManager.getLayouts();
        const targetLayout = storedLayouts.find((layout) => layout.id !== currentLayoutId);
        setSelectedLayoutId(targetLayout?.id);
        dispatch({ type: "select-id", id: targetLayout?.id });
      }
      await layoutManager.deleteLayout({ id: item.id });
    },
    [
      analytics,
      currentLayoutId,
      dispatch,
      layoutManager,
      setSelectedLayoutId,
      state.selectedIds.length,
    ],
  );

  const createNewLayout = useCallbackWithToast(async () => {
    if (!(await promptForUnsavedChanges())) {
      return;
    }
    const name = `Unnamed layout ${moment(currentDateForStorybook).format("l")} at ${moment(
      currentDateForStorybook,
    ).format("LT")}`;
    const panelState: Omit<PanelsState, "name" | "id"> = {
      configById: {},
      globalVariables: {},
      userNodes: {},
      linkedGlobalVariables: [],
      playbackConfig: defaultPlaybackConfig,
    };
    const newLayout = await layoutManager.saveNewLayout({
      name,
      data: panelState as PanelsState,
      permission: "CREATOR_WRITE",
    });
    void onSelectLayout(newLayout);

    void analytics.logEvent(AppEvent.LAYOUT_CREATE);
  }, [promptForUnsavedChanges, currentDateForStorybook, layoutManager, onSelectLayout, analytics]);

  const onExportLayout = useCallbackWithToast(
    async (item: Layout) => {
      const content = JSON.stringify(item.working?.data ?? item.baseline.data, undefined, 2) ?? "";
      downloadTextFile(content, `${item.name}.json`);
      void analytics.logEvent(AppEvent.LAYOUT_EXPORT, { permission: item.permission });
    },
    [analytics],
  );

  const onShareLayout = useCallbackWithToast(
    async (item: Layout) => {
      const name = await prompt({
        title: "Share a copy with your team",
        subText: "Team layouts can be used and changed by other members of your team.",
        initialValue: item.name,
        label: "Layout name",
      });
      if (name != undefined) {
        const newLayout = await layoutManager.saveNewLayout({
          name,
          data: item.working?.data ?? item.baseline.data,
          permission: "ORG_WRITE",
        });
        void analytics.logEvent(AppEvent.LAYOUT_SHARE, { permission: item.permission });
        await onSelectLayout(newLayout);
      }
    },
    [analytics, layoutManager, onSelectLayout, prompt],
  );

  const onOverwriteLayout = useCallbackWithToast(
    async (item: Layout) => {
      // We don't need to confirm the multiple selection case because we force users to save
      // or abandon changes before selecting another layout with unsaved changes to the current
      // shared layout.
      if (state.selectedIds.length > 1) {
        dispatch({ type: "queue-multi-action", action: "save" });
        return;
      }

      if (layoutIsShared(item)) {
        const response = await confirm({
          title: `Update “${item.name}”?`,
          prompt:
            "Your changes will overwrite this layout for all team members. This cannot be undone.",
          ok: "Save",
        });
        if (response !== "ok") {
          return;
        }
      }
      await layoutManager.overwriteLayout({ id: item.id });
      void analytics.logEvent(AppEvent.LAYOUT_OVERWRITE, { permission: item.permission });
    },
    [analytics, confirm, dispatch, layoutManager, state.selectedIds.length],
  );

  const onRevertLayout = useCallbackWithToast(
    async (item: Layout) => {
      if (state.selectedIds.length > 1) {
        dispatch({ type: "queue-multi-action", action: "revert" });
        return;
      }

      await layoutManager.revertLayout({ id: item.id });
      void analytics.logEvent(AppEvent.LAYOUT_REVERT, { permission: item.permission });
    },
    [analytics, dispatch, layoutManager, state.selectedIds.length],
  );

  const onMakePersonalCopy = useCallbackWithToast(
    async (item: Layout) => {
      const newLayout = await layoutManager.makePersonalCopy({
        id: item.id,
        name: `${item.name} copy`,
      });
      await onSelectLayout(newLayout);
      void analytics.logEvent(AppEvent.LAYOUT_MAKE_PERSONAL_COPY, {
        permission: item.permission,
        syncStatus: item.syncInfo?.status,
      });
    },
    [analytics, layoutManager, onSelectLayout],
  );

  const importLayout = useCallbackWithToast(async () => {
    if (!(await promptForUnsavedChanges())) {
      return;
    }
    const fileHandles = await showOpenFilePicker({
      multiple: true,
      excludeAcceptAllOption: false,
      types: [
        {
          description: "JSON Files",
          accept: {
            "application/json": [".json"],
          },
        },
      ],
    });
    if (fileHandles.length === 0) {
      return;
    }

    const newLayouts = await Promise.all(
      fileHandles.map(async (fileHandle) => {
        const file = await fileHandle.getFile();
        const layoutName = path.basename(file.name, path.extname(file.name));
        const content = await file.text();

        if (!isMounted()) {
          return;
        }

        let parsedState: unknown;
        try {
          parsedState = JSON.parse(content);
        } catch (err) {
          enqueueSnackbar(`${file.name} is not a valid layout: ${err.message}`, {
            variant: "error",
          });
          return;
        }

        if (typeof parsedState !== "object" || !parsedState) {
          enqueueSnackbar(`${file.name} is not a valid layout`, { variant: "error" });
          return;
        }

        const data = parsedState as PanelsState;
        const newLayout = await layoutManager.saveNewLayout({
          name: layoutName,
          data,
          permission: "CREATOR_WRITE",
        });
        return newLayout;
      }),
    );

    if (!isMounted()) {
      return;
    }
    const newLayout = newLayouts.find((layout) => layout != undefined);
    if (newLayout) {
      void onSelectLayout(newLayout);
    }
    void analytics.logEvent(AppEvent.LAYOUT_IMPORT, { numLayouts: fileHandles.length });
  }, [
    analytics,
    enqueueSnackbar,
    isMounted,
    layoutManager,
    onSelectLayout,
    promptForUnsavedChanges,
  ]);

  const layoutDebug = useContext(LayoutStorageDebuggingContext);

  const pendingMultiAction = state.multiAction?.ids != undefined;

  const anySelectedModifiedLayouts = useMemo(() => {
    return [layouts.value?.personal ?? [], layouts.value?.shared ?? []]
      .flat()
      .some((layout) => layout.working != undefined && state.selectedIds.includes(layout.id));
  }, [layouts, state.selectedIds]);

  const [clouding, setClouding] = useState(false);
  const syncPersonLayouts = useCallback(() => {
    const personalLayouts = layouts.value?.personal;
    if (!personalLayouts) {
      return;
    }

    if (!currentCowaUser) {
      userLoginModal.openLoginModal();
      return;
    }

    if (clouding) return;

    const ss = personalLayouts.reduce((t, p) => {
      if (p.baseline.data.globalVariables) {
        // exception : p.baseline.data 存在 key 0, 1, 2, 3, 4, 5, 6...，configById
        const data = {
          layout_name: p.name,
          layout_uuid: p.id,
          ...(p.working?.data ?? p.baseline.data),
        };
        // delete localhdMap in 3D!
        Object.entries(data.configById).forEach(([k, v]) => {
          if (k.startsWith("3D!")) {
            v.localHdMap = undefined;
          }
        });

        t.push(data);
      }
      return t;
    }, [] as CowaPanelsState[]);

    setClouding(true);
    messageApi.open({
      key: tokenId,
      type: "loading",
      content: "上传备份layouts中...",
    });
    // console.log(ss);
    cowaApi
      .save_layout(ss, currentCowaUser.feishu_id)
      .then((_v: any) => {
        messageApi.open({
          key: tokenId,
          type: "success",
          content: "上传备份layouts同步完成",
          duration: 2,
        });
      })
      .catch((msg) => {
        messageApi.error({
          key: tokenId,
          type: "error",
          content: msg,
          duration: 2,
        });
      })
      .finally(() => {
        setClouding(false);
      });
  }, [currentCowaUser, clouding, layouts.value?.personal]);

  const checkLayouts = useCallback(() => {
    const personalLayouts = layouts.value?.personal;
    if (!personalLayouts) {
      return;
    }

    if (!currentCowaUser) {
      userLoginModal.openLoginModal();
      return;
    }

    if (clouding) return;

    const { feishu_id } = currentCowaUser;

    cowaApi
      .list_layouts(feishu_id, false)
      .then((v: any) => {
        const list = v.data.layouts as LayoutCloudType[];
        setLayoutCloudList(list);

        if (!list.length) {
          message.warning("云端无可用数据", 2);
          return;
        }
        setOpenModal(true);
      })
      .catch((e: string) => {
        message.error(e, 2);
      });
  }, [currentCowaUser, clouding, layouts.value?.personal]);

  return (
    <SidebarContent
      title="Layouts"
      helpContent={helpContent}
      disablePadding
      trailingItems={[
        (layouts.loading || state.busy || pendingMultiAction) && (
          <Stack key="loading" alignItems="center" justifyContent="center" padding={1}>
            <CircularProgress size={18} variant="indeterminate" />
          </Stack>
        ),
        (!state.online || state.error != undefined) && (
          <IconButton color="primary" key="offline" disabled title="Offline">
            <CloudOffIcon />
          </IconButton>
        ),
        <div style={{ padding: "0 8px" }} hidden={!clouding}>
          <CircularProgress size={18} key="cowa-cloud-loading" />
        </div>,
        <IconButton
          color="primary"
          key="add-layout"
          onClick={createNewLayout}
          aria-label="Create new layout"
          data-testid="add-layout"
          title="Create new layout"
        >
          <AddIcon />
        </IconButton>,
        <IconButton
          color="primary"
          key="import-layout"
          onClick={importLayout}
          aria-label="Import layout"
          title="Import layout"
        >
          <FileOpenOutlinedIcon />
        </IconButton>,
        <IconButton
          color="primary"
          key="cowa-cloud-layout"
          onClick={checkLayouts}
          title="查看云端数据"
          disabled={clouding}
        >
          <Icon path={mdiDatabase} size={1} />
        </IconButton>,
        <IconButton
          color="primary"
          key="cowa-cloud-layout"
          onClick={syncPersonLayouts}
          title="同步layouts到云端"
          disabled={clouding}
        >
          <CloudUploadOutlinedIcon />
        </IconButton>,
      ].filter(Boolean)}
    >
      {contextHolder}
      {userLoginModal.contextHolder}
      {unsavedChangesPrompt}
      <Stack fullHeight gap={2} style={{ pointerEvents: pendingMultiAction ? "none" : "auto" }}>
        <LayoutSection
          title={layoutManager.supportsSharing ? "Personal" : undefined}
          emptyText="Add a new layout to get started with Foxglove Studio!"
          items={layouts.value?.personal}
          anySelectedModifiedLayouts={anySelectedModifiedLayouts}
          multiSelectedIds={state.selectedIds}
          selectedId={currentLayoutId}
          onSelect={onSelectLayout}
          onRename={onRenameLayout}
          onDuplicate={onDuplicateLayout}
          onDelete={onDeleteLayout}
          onShare={onShareLayout}
          onExport={onExportLayout}
          onOverwrite={onOverwriteLayout}
          onRevert={onRevertLayout}
          onMakePersonalCopy={onMakePersonalCopy}
        />
        <Stack flexGrow={1} />
        {layoutDebug && (
          <Stack
            gap={0.5}
            padding={1}
            position="sticky"
            style={{
              bottom: 0,
              left: 0,
              right: 0,
              background: theme.palette.background.default,
              ...debugBorder,
            }}
          >
            <Stack direction="row" flex="auto" gap={1}>
              <Button
                onClick={async () => {
                  await layoutDebug.syncNow();
                  await reloadLayouts();
                }}
              >
                Sync
              </Button>

              <Stack flex="auto" />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={layoutManager.isOnline}
                      onChange={(_, checked) => {
                        layoutDebug.setOnline(checked);
                      }}
                    />
                  }
                  label={layoutManager.isOnline ? "Online" : "Offline"}
                />
              </FormGroup>
            </Stack>
          </Stack>
        )}
      </Stack>
      {currentCowaUser && (
        <LayoutCloudModal
          openModal={openModal}
          setOpenModal={setOpenModal}
          layoutCloudList={layoutCloudList}
          currentCowaUser={currentCowaUser}
          layoutManager={layoutManager}
          onSelectLayout={onSelectLayout}
        />
      )}
    </SidebarContent>
  );
}

interface TableDataType extends LayoutCloudType {
  key: string;
  index: number;
}
const { Column } = Table;

const LayoutCloudModal = (props: {
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  layoutCloudList: LayoutCloudType[];
  currentCowaUser: CowaUser;
  layoutManager: ILayoutManager;
  onSelectLayout: Function;
}) => {
  const {
    openModal,
    setOpenModal,
    layoutCloudList,
    currentCowaUser,
    layoutManager,
    onSelectLayout,
  } = props;

  const [messageApi, contextHolder] = message.useMessage();

  const [list, setList] = useState<TableDataType[]>([]);
  const [curList, setCurList] = useState<TableDataType[]>([]);
  const [str, setStr] = useState("");

  useDebounceEffect(
    () => {
      if (str === "") return setCurList(list);

      setCurList(
        list
          .filter((l) => l.layout_name.includes(str))
          .map((v, k) => ({
            ...v,
            index: k + 1,
          })),
      );
    },
    [str, list],
    { wait: 300 },
  );

  const afterClose = useCallback(() => {
    setStr("");
  }, []);

  useEffect(() => {
    setList(
      layoutCloudList.map((m, mk) => ({
        ...m,
        index: mk + 1,
        key: m.layout_uuid,
      })),
    );
  }, [layoutCloudList]);

  const addLayout = useCallback(
    async (record: TableDataType) => {
      const layoutData = await cowaApi
        .list_one_layout(currentCowaUser.feishu_id, record.layout_uuid)
        .then((v: any) => {
          const layout_data = v.data.layout?.layout_data as CowaPanelsState | null;
          if (!layout_data) {
            messageApi.error("未查找到相关数据，请刷新重试", 2);
          }

          return layout_data;
        })
        .catch((e: string) => {
          messageApi.error(e, 2);
        });

      if (!layoutData) {
        return;
      }

      const data: any = { ...layoutData };
      delete data.layout_name;
      delete data.layout_uuid;

      const newLayout = await layoutManager.saveNewLayout({
        name: "__" + layoutData.layout_name,
        data: data as PanelsState,
        permission: "CREATOR_WRITE",
      });

      onSelectLayout(newLayout);

      messageApi.success("数据拉取成功", 2);
      // const newLayout = await layoutManager.saveNewLayout({
      //   name: `${item.name} copy`,
      //   data: item.working?.data ?? item.baseline.data,
      //   permission: "CREATOR_WRITE",
      // });
    },
    [layoutManager, onSelectLayout, currentCowaUser.feishu_id],
  );

  const delLayout = useCallback(
    (record: TableDataType) => {
      // console.log(record);
      cowaApi
        .delete_layouts(currentCowaUser.feishu_id, [record.layout_uuid])
        .then(() => {
          return cowaApi.list_layouts(currentCowaUser.feishu_id, false).then((v) => {
            const list = v.data.layouts as LayoutCloudType[];
            setList(
              list.map((m, mk) => ({
                ...m,
                index: mk + 1,
                key: m.layout_uuid,
              })),
            );

            messageApi.success("删除成功", 2);
          });
        })
        .catch((e: string) => {
          messageApi.error(e, 2);
        });
    },
    [currentCowaUser.feishu_id],
  );

  return (
    <>
      {contextHolder}
      <Modal
        open={openModal}
        width="800px"
        onCancel={() => setOpenModal(false)}
        afterClose={afterClose}
        maskClosable={false}
        title="个人布局云端数据"
        destroyOnClose
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        styles={{
          wrapper: {
            top: -40,
          },
          body: { overflowY: "auto", height: "700px", paddingRight: 10 },
        }}
        footer={null}
      >
        <TextField
          label="layout name filter"
          value={str}
          onChange={(e) => setStr(e.target.value.toLocaleLowerCase())}
          variant="standard"
          sx={{
            "& .MuiInputBase-input": {
              color: "#000",
            },
          }}
        />
        <Table dataSource={curList} pagination={false}>
          <Column title="" dataIndex="index" key="index" />
          <Column title="layout_name" dataIndex="layout_name" key="layout_name" width={100} />
          <Column title="layout_uuid" dataIndex="layout_uuid" key="layout_uuid" />
          <Column
            title=""
            key="delete_btn"
            width={160}
            render={(_: any, record: TableDataType) => (
              <Space
                size="middle"
                style={{
                  userSelect: "none",
                }}
              >
                <a onClick={() => addLayout(record)}>拉取</a>
                {/* <a>分享</a> */}
                <Popconfirm
                  title="删除"
                  description={`确定删除云端保存的布局 -- ${record.layout_name}？`}
                  onConfirm={() => delLayout(record)}
                  okText="Yes"
                  cancelText="No"
                >
                  <a>删除</a>
                </Popconfirm>
              </Space>
            )}
          />
        </Table>
      </Modal>
    </>
  );
};
