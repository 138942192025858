
// notification
type NOTIFICATION_TYPE = {
  description: string;

}
export const NOTIFICATION_TYPES_MAP = {
  'notification_type_on_seek_image': {
    description: 'h264视频流播放时，为了立刻渲染图像，可以按住shift，点击进度条。此时点击位置可能会往前漂移几秒'
  } as NOTIFICATION_TYPE
}


export enum SSO_UNION {
  SSO_TOKEN_UNION_TYPE = 'sso_token_union',
  SSO_CODE_TYPE = 'sso_code',
  SSO_FEISHU_ID_TYPE = 'sso_feishu_id',
}

export type SSO_TOKEN_UNION_TYPE = {
  access_token: string
  refresh_token: string
  expires_in?: number
}

export type SSO_CODE_TYPE = string
export type SSO_FEISHU_ID_TYPE = string
