import { Button, Typography, Divider } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { Button as AntdButton, message, Space } from "antd";
import { v4 as uuid } from "uuid";

import { useCurrentCowaUser } from "@foxglove/studio-base/context/cowa/CurrentCowaUserContext";
import { enterLoginPage } from "@foxglove/studio-base/cowaApi";
import AccountSyncGraphic from "@foxglove/studio-base/components/AccountSettingsSidebar/AccountSyncGraphic";
import { useCallback, useMemo } from "react";

const useStyles = makeStyles()((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    color: theme.palette.primary.main,
  },
}));

export default function SigninForm(): JSX.Element {
  const { classes } = useStyles();
  const { loadingUser, currentCowaUser, signOut, refreshStatus } = useCurrentCowaUser();

  const id = useMemo(() => uuid(), []);
  const [messageApi, contextHolder] = message.useMessage();

  const refreshUser = useCallback(async () => {
    messageApi.open({
      key: id,
      type: "loading",
      content: "更新用户信息中...",
    });
    return refreshStatus()
      .then((_v) => {
        messageApi.open({
          key: id,
          type: "success",
          content: "更新用户信息成功",
          duration: 2,
        });
      })
      .catch(() => {
        messageApi.open({
          key: id,
          type: "error",
          content: "更新失败，请稍后尝试重新登陆",
          duration: 2,
        });

        return signOut();
      });
  }, [refreshStatus]);

  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <AccountSyncGraphic width={192} />
      </div>
      <Typography variant="body1" component="div">
        <>
          登录酷哇统一登录平台:
          <ul>
            {/* <li>Share your layouts with others</li> */}
            <li>与数据中心，车联网等平台交互</li>
            <li>持久化layouts（未完成）</li>
          </ul>
        </>
      </Typography>
      {loadingUser ? (
        <AntdButton type="primary" loading>
          loading user
        </AntdButton>
      ) : currentCowaUser ? (
        <div
          style={{
            textAlign: "center",
          }}
        >
          <div
            style={{
              fontSize: "1.15rem",
              fontWeight: "bold",
            }}
          >
            {`hi ${currentCowaUser.name}`}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              wordBreak: "break-all",
            }}
          >
            <h4 style={{ width: "66px" }}>feishu_id：</h4>
            <h4 style={{ flex: 1 }}> {currentCowaUser.feishu_id}</h4>
          </div>
          <Divider variant="middle" />
          <Space
            style={{
              marginTop: "2rem",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={refreshUser}
              sx={{
                marginRight: "1rem",
              }}
            >
              刷新信息
            </Button>
            <Button variant="contained" color="primary" onClick={signOut}>
              退出账户
            </Button>
          </Space>
        </div>
      ) : (
        <Button variant="contained" color="primary" onClick={enterLoginPage}>
          点击进入登录页面
        </Button>
      )}
      {contextHolder}
    </div>
  );
}
