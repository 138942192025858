//@ts-nocheck
// This Source Code Form is subject to the terms of the Mozilla Public
// License, v2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/
//
// This file incorporates work covered by the following copyright and
// permission notice:
//
//   Copyright 2018-2021 Cruise LLC
//
//   This source code is licensed under the Apache License, Version 2.0,
//   found at http://www.apache.org/licenses/LICENSE-2.0
//   You may not use this file except in compliance                 with the License.

import {
  CopySelect20Regular,
  Delete20Regular,
  FullScreenMaximize20Regular,
  ShapeSubtract20Regular,
  SplitHorizontal20Regular,
  SplitVertical20Regular,
} from "@fluentui/react-icons";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Divider, Menu, MenuItem } from "@mui/material";
import { MouseEvent, useCallback, useContext, useMemo, useRef, useState } from "react";
import { MosaicContext, MosaicNode, MosaicWindowContext } from "react-mosaic-component";
import { makeStyles } from "tss-react/mui";

import PanelContext from "@foxglove/studio-base/components/PanelContext";
import ChangePanelMenu from "@foxglove/studio-base/components/PanelToolbar/ChangePanelMenu";
import ToolbarIconButton from "@foxglove/studio-base/components/PanelToolbar/ToolbarIconButton";
import { getPanelTypeFromMosaic } from "@foxglove/studio-base/components/PanelToolbar/utils";
import { useCurrentLayoutActions } from "@foxglove/studio-base/context/CurrentLayoutContext";
import { Modal, Checkbox } from "antd";
import { useLayoutManager } from "@foxglove/studio-base/context/LayoutManagerContext";
import { Layout, LayoutID } from "@foxglove/studio-base/services/ILayoutStorage";
import { PanelConfig } from "@foxglove/studio-base/types/panels";

type Props = {
  isUnknownPanel: boolean;
};

const useStyles = makeStyles()((theme) => ({
  error: { color: theme.palette.error.main },
  icon: {
    marginRight: theme.spacing(-1),
  },
  menuItem: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",

    ".root-span": {
      display: "flex",
      marginLeft: theme.spacing(-0.25),
    },
    "&.Mui-selected": {
      backgroundColor: theme.palette.action.hover,

      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
}));

export function PanelActionsDropdown({ isUnknownPanel }: Props): JSX.Element {
  const { classes, cx } = useStyles();
  const [modal, contextHolder] = Modal.useModal();
  const layoutManager = useLayoutManager();

  const [menuAnchorEl, setMenuAnchorEl] = useState<undefined | HTMLElement>(undefined);
  const [subMenuAnchorEl, setSubmenuAnchorEl] = useState<undefined | HTMLElement>(undefined);

  const menuOpen = Boolean(menuAnchorEl);
  const submenuOpen = Boolean(subMenuAnchorEl);

  const panelContext = useContext(PanelContext);
  const tabId = panelContext?.tabId;
  const { mosaicActions } = useContext(MosaicContext);
  const { mosaicWindowActions } = useContext(MosaicWindowContext);
  const {
    getCurrentLayoutState: getCurrentLayout,
    closePanel,
    splitPanel,
  } = useCurrentLayoutActions();
  const getPanelType = useCallback(
    () => getPanelTypeFromMosaic(mosaicWindowActions, mosaicActions),
    [mosaicActions, mosaicWindowActions],
  );

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSubmenuAnchorEl(undefined);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setSubmenuAnchorEl(undefined);
    setMenuAnchorEl(undefined);
  };

  const handleSubmenuClick = (event: MouseEvent<HTMLElement>) => {
    if (subMenuAnchorEl !== event.currentTarget) {
      setSubmenuAnchorEl(event.currentTarget);
    }
    setMenuAnchorEl(undefined);
  };

  const handleSubmenuClose = useCallback(() => {
    setSubmenuAnchorEl(undefined);
  }, []);

  const handleSubmenuMouseEnter = (event: MouseEvent<HTMLElement>) => {
    setSubmenuAnchorEl(event.currentTarget);
  };

  const close = useCallback(() => {
    closePanel({
      tabId,
      root: mosaicActions.getRoot() as MosaicNode<string>,
      path: mosaicWindowActions.getPath(),
    });
    handleMenuClose();
  }, [closePanel, mosaicActions, mosaicWindowActions, tabId]);

  const split = useCallback(
    (id: string | undefined, direction: "row" | "column") => {
      const type = getPanelType();
      if (id == undefined || type == undefined) {
        throw new Error("Trying to split unknown panel!");
      }

      const config = getCurrentLayout().selectedLayout?.data?.configById[id] ?? {};
      splitPanel({
        id,
        tabId,
        direction,
        root: mosaicActions.getRoot() as MosaicNode<string>,
        path: mosaicWindowActions.getPath(),
        config,
      });
      handleMenuClose();
    },
    [getCurrentLayout, getPanelType, mosaicActions, mosaicWindowActions, splitPanel, tabId],
  );

  const enterFullscreen = useCallback(() => {
    panelContext?.enterFullscreen();
    handleMenuClose();
  }, [panelContext]);

  const copyToOtherLayouts = useCallback(async (id: string | undefined) => {
    if (id == undefined) {
      return modal.error({
        title: "Trying to copy unknown panel",
      });
    }

    const storedLayouts = await layoutManager.getLayouts();
    const selectedLayout = getCurrentLayout().selectedLayout;
    if (!selectedLayout) {
      return modal.error({
        title: "no panel selected",
      });
    }

    const otherLayouts = storedLayouts.filter((l) => l.id !== selectedLayout.id);

    if (!otherLayouts.length) {
      return modal.warning({
        title: "当前无layout可用",
      });
    }

    const config = JSON.parse(
      JSON.stringify(selectedLayout.data?.configById[id] ?? {})!,
    ) as PanelConfig;

    console.log(selectedLayout, config, id);
    // await layoutManager.updateLayout({ id: item.id, name: newName });
    let layoutIds: string[] = [];
    modal.confirm({
      title: "选择",
      icon: <CopySelect20Regular />,
      content: (
        <Checkbox.Group
          options={otherLayouts.map((l) => ({
            label: l.name,
            value: l.id as string,
          }))}
          onChange={(values) => (layoutIds = values as string[])}
        />
      ),
      okText: "确认",
      cancelText: "取消",
      // onOk: () => {
      //   // console.log(layoutIds);
      //   if (layoutIds.length) {
      //     Promise.all(
      //       layoutIds.map((layoutId) => {
      //         const otherLayout = JSON.parse(
      //           JSON.stringify(otherLayouts.find((l) => l.id === layoutId))!,
      //         ) as Layout;
      //         // console.log(otherLayout);
      //         if (!otherLayout.working) return;
      //         otherLayout.working.data.configById[id] = config;

      //         // console.log(otherLayout);
      //         return layoutManager.updateLayout({
      //           id: layoutId as LayoutID,
      //           data: otherLayout.working.data,
      //         });
      //       }),
      //     )
      //       .then((v) => {
      //         console.log("success");
      //       })
      //       .catch((e) => {
      //         console.log(e);
      //       });
      //   } else {
      //     modal.warning({
      //       content: "未选中任何layout",
      //     });
      //   }
      // },
    });

    return undefined;
  }, []);

  const menuItems = useMemo(() => {
    const items = [];

    if (!isUnknownPanel) {
      items.push(
        {
          key: "hsplit",
          text: "Split horizontal",
          icon: <SplitHorizontal20Regular />,
          onClick: () => split(panelContext?.id, "column"),
        },
        {
          key: "vsplit",
          text: "Split vertical",
          icon: <SplitVertical20Regular />,
          onClick: () => split(panelContext?.id, "row"),
        },
      );
    }

    if (panelContext?.isFullscreen !== true) {
      items.push({
        key: "enter-fullscreen",
        text: "Fullscreen",
        icon: <FullScreenMaximize20Regular />,
        onClick: enterFullscreen,
        "data-testid": "panel-menu-fullscreen",
      });
    }

    // items.push({ key: "divider1", type: "divider" });

    // items.push({
    //   key: "copy-to-other-layouts",
    //   text: "Copy to other layouts",
    //   icon: <CopySelect20Regular />,
    //   onClick: () => copyToOtherLayouts(panelContext?.id),
    // });

    items.push({ key: "divider2", type: "divider" });

    items.push({
      key: "remove",
      text: "Remove panel",
      icon: <Delete20Regular />,
      onClick: close,
      "data-testid": "panel-menu-remove",
      className: classes.error,
    });

    return items;
  }, [
    classes.error,
    close,
    enterFullscreen,
    isUnknownPanel,
    panelContext?.id,
    panelContext?.isFullscreen,
    split,
  ]);

  const buttonRef = useRef<HTMLDivElement>(ReactNull);
  const type = getPanelType();

  if (type == undefined) {
    return <></>;
  }

  return (
    <div ref={buttonRef}>
      {contextHolder}
      <ToolbarIconButton
        id="panel-menu-button"
        aria-controls={menuOpen ? "panel-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleMenuClick}
        data-testid="panel-menu"
        title="More"
      >
        <MoreVertIcon />
      </ToolbarIconButton>
      <Menu
        id="panel-menu"
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        MenuListProps={{
          "aria-labelledby": "panel-menu-button",
          dense: true,
        }}
      >
        <MenuItem
          className={classes.menuItem}
          selected={submenuOpen}
          id="change-panel-button"
          aria-controls={submenuOpen ? "change-panel-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={submenuOpen ? "true" : undefined}
          onClick={handleSubmenuClick}
          onMouseEnter={handleSubmenuMouseEnter}
        >
          <ShapeSubtract20Regular />
          Change Panel
          <ChevronRightIcon className={classes.icon} fontSize="small" />
        </MenuItem>
        <ChangePanelMenu anchorEl={subMenuAnchorEl} onClose={handleSubmenuClose} tabId={tabId} />
        <Divider variant="middle" />
        {menuItems.map((item, idx) =>
          item.type === "divider" ? (
            <Divider key={`divider-${idx}`} variant="middle" />
          ) : (
            <MenuItem
              key={item.key}
              onClick={item.onClick}
              onMouseEnter={() => setSubmenuAnchorEl(undefined)}
              className={cx(classes.menuItem, item.className)}
              data-testid={item["data-testid"]}
            >
              {item.icon}
              {item.text}
            </MenuItem>
          ),
        )}
      </Menu>
    </div>
  );
}
