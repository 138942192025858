import { message, Modal } from "antd";
import { createContext, useCallback, useContext } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import cowaApi, { enterLoginPage } from "@foxglove/studio-base/cowaApi";

export interface CowaUser {
  id: string;
  feishu_id: string;
  name: string;
}

export interface CurrentCowaUser {
  currentCowaUser: CowaUser | undefined;
  signOut: () => Promise<void>;
  loadingUser: boolean;
  refreshStatus: () => Promise<void>;
}

const CurrentCowaUserContext = createContext<CurrentCowaUser>({
  currentCowaUser: undefined,
  signOut: async () => {},
  loadingUser: true,
  refreshStatus: async () => {},
});
CurrentCowaUserContext.displayName = "CurrentCowaUserContext";

export function useCurrentCowaUser(): CurrentCowaUser {
  return useContext(CurrentCowaUserContext);
} //  can only use pubsub to link app with threedeerender, both of which are the root

export const useUserLoginModal = () => {
  const [modal, contextHolder] = Modal.useModal();

  const openLoginModal = useCallback(async () => {
    return cowaApi
      .ping()
      .then(() => {
        const instance = modal.confirm({
          maskClosable: false,
          title: "用户未登录OSS",
          content: "该功能需要获取用户id",
          okText: "点击进入登录页面",
          cancelText: "取消",
          icon: <ExclamationCircleOutlined />,
          onOk: () => {
            enterLoginPage();
            instance.destroy();
          },
        });
      })
      .catch((e: String) => {
        message.error(e, 2);
      });
  }, []);

  return { openLoginModal, contextHolder };
};

export default CurrentCowaUserContext;
