// This Source Code Form is subject to the terms of the Mozilla Public
// License, v2.0. If a copy of the MPL was not distributed with this
// file, You can obtain one at http://mozilla.org/MPL/2.0/

import {
  IDataSourceFactory,
  DataSourceFactoryInitializeArgs,
} from "@foxglove/studio-base/context/PlayerSelectionContext";
import { IterablePlayer, WorkerIterableSource } from "@foxglove/studio-base/players/IterablePlayer";
import { Player } from "@foxglove/studio-base/players/types";

class CowaCrbagLocalDataSourceFactory implements IDataSourceFactory {
  public id = "crbag-local-files";
  public type: IDataSourceFactory["type"] = "cowa-files";
  public displayName = ".record files";
  public iconName: IDataSourceFactory["iconName"] = "OpenFile";
  public supportedFileTypes = [".record"];

  public initialize(args: DataSourceFactoryInitializeArgs): Player | undefined {
    const files = args.files;
    if (!files) {
      return;
    }

    console.log(files);

    const source = new WorkerIterableSource({
      sourceType: "cowa-files",
      initArgs: { files },
    });

    return new IterablePlayer({
      metricsCollector: args.metricsCollector,
      source,
      name: files.map((f) => f.name).join(", "),
      sourceId: this.id,
    });
  }
}

export default CowaCrbagLocalDataSourceFactory;
