import {
  MessageEvent,
} from "@foxglove/studio-base/players/types";

import { useMessageReducer } from "@foxglove/studio-base/PanelAPI";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import PubSub from 'pubsub-js'
import { MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME, OFF_MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME, OFF_TOPIC_IMAGE_PANEL_KEYFRAME, TOPIC_IMAGE_PANEL_KEYFRAME } from "@foxglove/studio-base/config/pubsubTypes";
import { v4 as uuidv4 } from "uuid";

type KeyframeDataMsg = {
  data: Uint8Array
};

export default (cameraTopic: string) => {

  const [id] = useState(() => uuidv4());

  const keyframeInfo = useCameraKeyFrameInfo(cameraTopic)

  useEffect(() => {
    if (!keyframeInfo || !keyframeInfo.data.length) return

    const res = new TextDecoder().decode(keyframeInfo.data)
    const jRes = (JSON.parse(res) as string[]).map(v => BigInt(v))
    PubSub.publish(TOPIC_IMAGE_PANEL_KEYFRAME, {
      keyframes: jRes,
      topic: cameraTopic,
      id,
    } as MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME)

    return () => {
      PubSub.publish(OFF_TOPIC_IMAGE_PANEL_KEYFRAME, {
        id,
        topic: cameraTopic
      } as OFF_MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME)
    }

  }, [keyframeInfo, cameraTopic])
}

const useCameraKeyFrameInfo = (cameraTopic: string) => {
  const keyframeTopic = useMemo(() => { return `${cameraTopic}/keyframe` }, [cameraTopic])

  return useMessageReducer<KeyframeDataMsg | undefined>({
    topics: [keyframeTopic],
    restore: useCallback((value) => value, []),
    addMessage: useCallback(
      (_value: KeyframeDataMsg | undefined, { message }: MessageEvent<unknown>) => {
        // console.log(message) // only once
        return message as KeyframeDataMsg;
      },
      [],
    ),
  });
}

export const useImageKeyframeMap = () => {
  const imageKeyframeMap = useRef(
    new Map<
      string,
      {
        keyframes: bigint[];
        ids: Set<string>;
      }
    >(),
  );

  const getLatestNanoSec = useCallback((nanoSec: bigint) => {
    // console.log(microSec)
    let min = BigInt(Number.MAX_VALUE)
    let minTopic: string | undefined = undefined
    for (let [topic, val] of imageKeyframeMap.current.entries()) {
      const { keyframes, } = val

      // console.log(keyframes)
      let l = 0, r = keyframes.length - 1, index = 0
      while (l <= r) {
        const mid = Math.floor((l + r) / 2)
        const mv = keyframes[mid]!
        if (mv >= nanoSec) {
          r = mid - 1
        } else {

          index = mid
          l = mid + 1
        }
      }

      if (min > keyframes[index]!) {
        min = keyframes[index]!
        minTopic = topic
      }
    }
    // return min == BigInt(Number.MAX_VALUE) ? nanoSec : min
    return {
      minTopic,
      lastestNanoSec: min == BigInt(Number.MAX_VALUE) ? nanoSec : min < nanoSec ? min : nanoSec
    }
  }, [])

  useEffect(() => {
    const token1 = PubSub.subscribe(
      TOPIC_IMAGE_PANEL_KEYFRAME,
      (_msg, data: MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME) => {

        let val = imageKeyframeMap.current.get(data.topic);
        if (!val) {
          val = {
            keyframes: data.keyframes,
            ids: new Set(),
          };

          imageKeyframeMap.current.set(data.topic, val);
        }
        val.ids.add(data.id);
      },
    );

    const token2 = PubSub.subscribe(
      OFF_TOPIC_IMAGE_PANEL_KEYFRAME,
      (_msg, data: OFF_MSG_TYPE_TOPIC_IMAGE_PANEL_KEYFRAME) => {

        const val = imageKeyframeMap.current.get(data.topic);
        val?.ids.delete(data.id);
        if (val?.ids.size === 0) {
          imageKeyframeMap.current.delete(data.topic);
        }
      },
    );

    return () => {
      PubSub.unsubscribe(token1);
      PubSub.unsubscribe(token2);
    }
  }, []);

  return {
    getLatestNanoSec
  }
}
